//
// _dropdown.scss
//

.dropdown-menu {
  box-shadow: $box-shadow-lg;
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  position: absolute;
  z-index: 1000;

  &.show {
    // top: 100% !important;
    top: 100%;
  }

  &.dropdown-megamenu {
    padding: 20px;
    left: 0 !important;
    right: 0 !important;
  }

  &[data-popper-placement='top-start'] {
    animation-name: DropDownSlideDown;
  }
}

@keyframes DropDownSlide {
  100% {
    transform: translateY(0);
  }

  0% {
    transform: translateY(10px);
  }
}

@keyframes DropDownSlideDown {
  100% {
    margin-bottom: 0;
  }

  0% {
    margin-bottom: 8px;
  }
}

@media (min-width: 600px) {
  .dropdown-menu-xl {
    width: 420px;
  }

  .dropdown-menu-lg {
    width: 320px;
  }

  .dropdown-menu-md {
    width: 240px;
  }
}

.dropdown-divider {
  border-top-color: $border-color;
}

// Dropdown Mega Menu

.dropdown-mega {
  position: static !important;
}

// Dropdown size

.dropdown-mega-menu-xl {
  width: 38rem;
}

.dropdown-mega-menu-lg {
  width: 26rem;
}

//
// Dropdown menu item color
//

@each $color, $value in $theme-colors {
  .dropdownmenu-#{$color} {
    .dropdown-item {
      &:focus,
      &:hover,
      &.active {
        background-color: rgba(($value), 0.07) !important;
        color: $value;
      }
    }
  }
}

[dir='ltr'] {
  .dropdown-menu-start {
    --bs-position: end;
  }

  .dropdown-menu-end {
    --bs-position: start;
  }
}

body[data-layout-mode='dark'] {
  // Dropdown
  .dropdown-menu {
    background-color: lighten($gray-dark-200, 2.5%);
    border-color: lighten($gray-dark-200, 3%);
    color: $gray-dark-500;
    box-shadow: 0 5px 6px rgba($gray-dark-200, 0.1);
  }

  .dropdown-item {
    color: $gray-dark-500;

    &:hover,
    &:active,
    &:focus {
      background-color: lighten($gray-dark-200, 5%);
    }

    &.active,
    &:active {
      background-color: lighten($gray-dark-200, 5%);
    }
  }

  .dropdown-divider {
    border-top-color: $gray-dark-300;
  }
}

.dropdown-toggle {
  &:after {
    display: none !important;
  }
}
