//
// forms.scss
//

[type='tel'],
[type='url'],
[type='email'],
[type='number'] {
  &::placeholder {
    text-align: left /*rtl: right*/;
  }
}

.form-check {
  position: relative;
  text-align: left /*rtl: right*/;
}

// checkbox input right

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: $form-check-padding-start;
  .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: $form-check-padding-start * -1;
  }

  .form-check-label {
    display: block;
  }
}

// checkbox

.form-checkbox-outline {
  .form-check-input {
    border-width: 2px;
    background-color: $card-bg;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: $card-bg !important;

      &[type='checkbox'] {
        background-image: none;
      }

      &:after {
        position: absolute;
        content: '\F012C';
        font-family: 'Material Design Icons';
        top: -4px !important;
        left: 1px; /*rtl: -4px */
        font-size: 16px;
        color: $dark;
      }
    }
  }
}

// radio

.form-radio-outline {
  .form-check-input {
    border-width: 2px;
    background-color: $card-bg;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: $card-bg !important;

      &[type='checkbox'] {
        background-image: none;
      }

      &:after {
        position: absolute;
        content: '\F0765';
        font-family: 'Material Design Icons';
        top: 7px !important;
        left: 4px;
        font-size: 6px;
      }
    }
  }
}

// checkbox color

@each $color, $value in $theme-colors {
  .form-check-#{$color} {
    .form-check-input {
      &:checked {
        background-color: $value;
        border-color: $value;
      }
    }
  }

  .form-radio-#{$color} {
    .form-check-input {
      &:checked {
        border-color: $value;
        background-color: $value;
        &:after {
          color: $value;
        }
      }
    }
  }
}

.form-check,
.form-check-input,
.form-check-label {
  cursor: pointer;
  margin-bottom: 0;
}

// Switch sizes

.form-switch-md {
  font-size: 20px;
  min-height: 26px;
  line-height: 26px;

  .form-check-label {
    font-size: $font-size-base;
    vertical-align: middle;
  }
}

.form-switch-lg {
  font-size: 26px;
  min-height: 36px;
  line-height: 36px;
  .form-check-label {
    font-size: $font-size-base;
    vertical-align: middle;
  }
}

.input-group-text {
  margin-bottom: 0px;
}

// card radio

.card-radio {
  background-color: $card-bg;
  border: 2px solid $card-border-color;
  border-radius: $border-radius;
  padding: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
}

.card-radio-label {
  display: block;
}

.card-radio-input {
  display: none;
  &:checked + .card-radio {
    border-color: $primary !important;
  }
}

body[data-layout-mode='dark'] {
  // Form Control
  .form-control {
    color: $gray-dark-500;
    background-color: lighten($gray-dark-200, 2%);
    border: 1px solid $gray-dark-300;
  }
  // form-select
  .form-select {
    color: $gray-dark-500;
    background-color: lighten($gray-dark-200, 2.5%);
    border: 1px solid $gray-dark-300;
    background-image: escape-svg(
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$gray-dark-800}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>")
    );
  }

  // form-check

  .form-check-input {
    background-color: $gray-dark-300;
    border-color: rgba($white, 0.25);
    &:checked {
      background-color: $form-check-input-checked-bg-color;
      border-color: $form-check-input-checked-border-color;
    }
  }

  // hstack

  .hstack {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
  }

  .vstack {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch;
  }

  // form switch

  .form-switch {
    .form-check-input {
      background-image: escape-svg($form-switch-checked-bg-image);
    }
  }

  // input group
  .input-group-text {
    background-color: $gray-dark-300;
    border-color: $gray-dark-300;
    color: $gray-dark-600;
  }

  .form-control {
    &::-webkit-file-upload-button {
      background-color: $gray-dark-300;
      color: $gray-dark-600;
    }
    &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
      background-color: $gray-dark-300;
    }
  }
}
