//
// coming-soon.scss
//

// countdownlist

.countdownlist {
  text-align: center;
  display: flex;
  gap: 24px;

  .countdownlist-item {
    width: 25%;
    &:last-of-type {
      .count-num {
        &::after {
          display: none;
        }
      }
    }
  }
  .count-title {
    font-size: 13px;
    font-weight: $font-weight-medium;
    display: block;
    margin-bottom: 8px;
    color: rgba($dark, 0.5);
    text-transform: uppercase;
  }

  .count-num {
    background-color: $card-bg;
    padding: 16px 8px;
    position: relative;
    border-radius: $card-border-radius;
    box-shadow: $box-shadow;
    font-weight: $font-weight-semibold;
    font-size: 32px;
    @media (max-width: 575.98px) {
      font-size: 18px;
    }

    &::after {
      content: ':';
      font-size: 20px;
      position: absolute;
      right: -16px;
      top: 50%;
      transform: translateY(-50%);
      color: $dark;
    }
  }
}

.countdown-endtxt {
  font-size: 24px;
  font-weight: $font-weight-semibold;
}

.countdown-input-group {
  max-width: 350px;
}

body[data-layout-mode='dark'] {
  .countdownlist {
    .count-title {
      color: $gray-dark-400;
    }
    .count-num {
      background-color: $gray-dark-300;
      color: $gray-dark-500;
      &::after {
        color: $gray-dark-300;
      }
    }
  }
}
