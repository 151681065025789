@import './icons.scss';
@import './mermaid.min.css';

//import scss light mode
@import './app.scss';
@import './bootstrap.scss';

// rtl Direction
// @import "./custom/rtl/bootstrap-rtl";
// @import "./custom/rtl/components-rtl";
// @import "./custom/rtl/float-rtl";
// @import "./custom/rtl/general-rtl";
// @import "./custom/rtl/pages-rtl";
// @import "./custom/rtl/plugins-rtl";
// @import "./custom/rtl/spacing-rtl";
// @import "./custom/rtl/structure-rtl";
// @import "./custom/rtl/text-rtl";

.flatpickr-weekday {
  background: #3980c0;
}

.cursor {
  cursor: pointer;
}

.lds-dual-ring {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 1111;
  background: rgba(0, 0, 0, 0.5);
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.heightCust {
  min-height: 300px !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.tr_head {
  background: #ecebeb;
}

.deleted {
  text-decoration: line-through;
}
.cent {
  padding: 50%;
}
.w175 {
  width: 170px;
}
.w185 {
  width: 158px;
}
.w-130 {
  width: 130px;
}
.w-105 {
  width: 105px;
}
.text-error {
  font-size: 90%;
  color: #fa6374;
  margin: 4px 0 0;
  padding: 0px 2px;
}
.companyButton {
  position: relative;
  svg {
    position: absolute;
    top: 52%;
    right: 15px;
    transform: translate(0, -50%);
  }
}

.table .table-light {
  color: #495057;
  border-color: #eff0f2;
  background-color: #f8f9fa;
}
.tranButton {
  margin-top: 32px;
}
#react-select-3-listbox,
#react-select-2-listbox,
#react-select-4-listbox {
  width: 200px !important;
}
.cwidth {
  width: 120px !important;
}
.CashBookTable {
  .table-responsive {
    max-height: calc(100vh - 340px);
  }
}

@media (max-width: 767px) {
  .comapnyName {
    display: none;
  }
}

.cashbookHeight {
  min-height: 150px;
}

.CashBookTable .Action {
  width: 90px;
  word-break: break-all;
}
.CashBookTable .account {
  width: 190px;
  word-break: break-all;
}

.CashBookTable .narration {
  width: calc(33.33% - 25px);
  word-break: break-all;
}

.CashBookTable .amount {
  width: calc(20.33% - 25px);
  word-break: break-all;
}

body .css-1jqq78o-placeholder {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.buttonRefres {
  background: 'white';
  color: '#495057';
  border: '1px solid #e2e5e8';
}
.width-100 {
  width: 100px !important;
}
.width-125 {
  width: 125px !important;
}
.width-150 {
  width: 150px !important;
}
.width-200 {
  width: 200px !important;
}
.width-75 {
  width: 70px !important;
}
.width-50 {
  width: 50px !important;
}
.transactions-table {
  .row {
    .col-auto {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
@media (max-width: 1500px) {
  .CashBookTable .amount {
    width: 105px;
  }
  .CashBookTable .narration {
    width: 120px;
  }
  // .CashBookTable .account {
  //   width: calc(100% - 300px);
  // }
}
@media (max-width: 767px) {
  .CashBookTable {
    .table-responsive {
      thead {
        display: none;
      }
      tbody {
        td {
          width: 100%;
          float: left;
          position: relative;
          padding: 0 5px 0 86px;
          margin: 7px 0;
          border: none;
          &::before {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 5px;
            font-weight: 600;
            color: var(--bs-table-hover-color);
            pointer-events: none;
          }
          &.account {
            &:before {
              content: 'Account : ';
            }
          }
          &.amount {
            &:before {
              content: 'Amount : ';
            }
          }
          &.narration {
            &:before {
              content: 'Narration : ';
            }
          }
          &.Action {
            &:before {
              content: 'Action : ';
            }
          }
          td {
            &.cursor {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}
.jouranaldate {
  width: 220px !important;
}

.purchase-card {
  min-height: 350px;
}

.list-search-input {
  border-radius: 50px;
}
.sc {
  position: sticky;
  top: 0;
  z-index: 1;
}
.sc {
  position: sticky;
  top: 0;
  z-index: 1;
}
.scNext {
  position: sticky;
  top: 0;
  z-index: 1;
}
.scNext {
  position: sticky;
  background: #fff;
  top: 48px;
  z-index: 1;
}
.table {
  tbody {
    --bs-table-bg: transparent;
  }
}

.cashbookMinHeight {
  min-height: 600px !important;
}

.paddingTop {
  margin-top: 70px;
}

.transactions-pdf {
  .transactions-pdf-left {
    width: 110px;
  }
  .transactions-pdf-right {
    width: calc(100% - 110px);
    ul {
      &.nav {
        max-width: 524px;
        margin-left: auto;
      }
    }
  }
}
body {
  .css-13cymwt-control {
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .transactions-pdf {
    .transactions-pdf-left {
      width: 100%;
      margin-bottom: 20px;
    }
    .transactions-pdf-right {
      width: 100%;
      ul {
        &.nav {
          max-width: 100%;
        }
      }
    }
  }
  .cashbookMinHeight {
    min-height: auto !important;
  }
  .scNext {
    top: 0;
  }
  .table > :not(:first-child) {
    border-top: 0px solid #8b8b8b;
  }
}

.jornalTable {
  width: 100px;
}
.navbar-brand-box {
  .logo-lg {
    img {
      max-width: 80%;
    }
  }
}

@media (min-width: 1340px) and (max-width: 1752px) {
  .transactions-table-width {
    .w175 {
      width: 148px;
    }
    .w-130 {
      width: 130px;
    }
    .width-150 {
      width: 80px !important;
    }
    .width-125 {
      width: 105px !important;
    }
    .width-100 {
      width: 85px !important;
    }
    .customWidthBank {
      width: 120px;
    }
  }
}
.customWidthBank {
  width: 120px;
}
.leaderSection {
  max-height: calc(100vh - 331px);
}

.leaderSticky {
  position: sticky;
  top: -2px;
  background: #fff;
}

.leaderSticky1 {
  position: sticky;
  top: 45px;
  background: #fff;
}
.leaderStickyBottom {
  position: sticky;
  bottom: 1px;
  box-shadow: 0px 0.2px 0px 0.1px;
  background: #fff;
}

.row0 {
  display: flex;
  align-items: center;
}
.row1 {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.row2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wp65 {
  width: 65%;
}
.wp35 {
  width: 35%;
}
.w50 {
  width: 50px;
}
.w90 {
  width: 90px;
}
.w120 {
  width: 120px;
}
.w150 {
  width: 150px;
}
.w200 {
  width: 200px;
}

.w220 {
  width: 220px;
}
.w250 {
  width: 250px;
}
.w300 {
  width: 300px;
}
.w500 {
  width: 500px;
}
.radioC {
  font-size: large;
  margin: 4px;
  margin-top: 5px;
}

.mt30 {
  margin-top: 30px;
}
.h100 {
  max-height: 95%;
  overflow-y: scroll;
}
.searchBar {
  border-radius: 20px;
}

.alignRight {
  text-align: right;
}
.centerDiv {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
#rightTable {
  padding-left: 20px;
}

.tdIcon {
  padding-top: 2% !important;
}

#formRow .col {
  margin-bottom: 20px !important;
}

.tabView {
  display: none !important;
}

.mobView {
  display: none !important;
}

.css-1nmdiq5-menu {
  z-index: 10000 !important;
}
.bgGrey {
  background: #f5f6f8;
}

.whiteBox {
  height: 300px;
}
.whiteBox2 {
  height: 150px;
}
.white {
  color: #ffffff;
}
.FBlue {
  font-weight: 800;
}
.mt30 {
  margin-top: 30px;
}

@media (max-width: 1000px) {
  #rightTable {
    display: none !important;
  }
  #leftTable {
    width: 100% !important;
  }
  #downTable {
    display: block !important;
  }
  .tabView {
    display: block !important;
  }
  .deskView {
    display: none !important;
  }
}

@media (max-width: 700px) {
  .mobView {
    display: block !important;
  }
  .deskView2 {
    display: none !important;
  }
}

.table-containerpurchase.react-table:has(.css-1nmdiq5-menu) {
  overflow-x: unset;
}

.logoMargin {
  margin-left: -6px;
}
.customScrollAttach {
  max-height: calc(100vh - 393px);
  overflow: auto;
}
.table {
  --bs-table-border-color: #8b8b8b;
  .table-light {
    border-color: #8b8b8b;
  }
  tr {
    td {
      padding: 5px 15px;
    }
  }
}
.page-content.abc.transactions-table-width {
  padding-bottom: 20px;
}
.form-control:focus {
  border: 2px solid #2684ff !important;
  border-color: #2684ff;
}
.tranButton,
.CashBookTable,
.table-containerpurchase {
  button:focus {
    border: 2px solid #2684ff !important;
    border-radius: 0.25rem !important;
  }
}
.noDAtaA {
  min-height: 400px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.abc,
.abcCdd {
  .lds-dual-ring {
    top: 0;
    left: 0;
  }
}
.changeHeight {
  min-height: 200px;
}
.table-containerpurchase {
  tr {
    td,
    th {
      padding: 6px 7px;
    }
  }
}

body[data-sidebar='dark'][data-sidebar-size='sm'] {
  min-height: auto;
}

.profit-loss-table {
  border: 1px solid #e2e5e8;
}
.profit-loss-table .profit-loss-row {
  border-bottom: 1px solid #e2e5e8;
  padding-bottom: 0;
  margin-bottom: 0px;
}
.profit-loss-table .profit-loss-row:nth-child(1),
.profit-loss-table .profit-loss-row[data-id='Opening Stock Value'] > span,
.profit-loss-table .profit-loss-row[data-id='Opening Stock Value'] > label,
.profit-loss-table .profit-loss-row[data-id='Total'] > span,
.profit-loss-table .profit-loss-row[data-id='Total'] > label {
  background-color: #eee;
  font-weight: bold;
  font-size: 16px;
}

.profit-loss-table .profit-loss-row span {
  width: calc(100% - 200px);
  box-sizing: border-box;

  padding: 10px;
}
.profit-loss-table .profit-loss-row label {
  width: 200px;
  margin-bottom: 0;

  padding: 10px 0 10px 10px;
  border-left: 1px solid #e2e5e8;
}
.profit-loss-table .profit-loss-ul {
  padding-left: 0; // 20px;
  border-left: 0px solid #000;
}
.profit-loss-table .profit-loss-ul .profit-loss-row {
  background-color: transparent;
  font-weight: normal;
  font-size: 12px;
  font-style: italic;
}
.profit-loss-table .profit-loss-ul .profit-loss-row > span.has-children-start {
  padding-left: 25px;
}
.profit-loss-table .profit-loss-ul .profit-loss-row span.has-children-inner-start {
  padding-left: 30px;
}
.profit-loss-table .profit-loss-ul .profit-loss-row > span.has-no-children-end {
  padding-left: 30px;
}
.profit-loss-table .profit-loss-ul .profit-loss-row > label {
  text-indent: 10px;
}

.profit-loss-ul .profit-loss-ul .has-children-start {
  padding-left: 25px;
}
.profit-loss-ul .profit-loss-ul .profit-loss-ul .has-children-start {
  padding-left: 35px !important;
}
