//
// lightbox.scss
//

.glightbox-clean {
  .gslide-title {
    font-family: $font-family-secondary;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 18px;
  }

  .gslide-desc {
    font-family: $font-family-base;
    font-size: 14px;
  }

  .gclose {
    border: 2px solid $white;
    border-radius: 7px;
    right: 10px;
    left: auto;
    @media (min-width: 992px) {
      right: 20px;
      left: auto;
    }
    svg {
      width: 12px;
    }
  }
}
